import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { MDBCard, MDBCardBody, MDBCol, MDBInput, MDBRow } from "mdbreact";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import Select from "react-select";
import Authentication from "./authentication";
import Footer from "./footer";
import Nav from "./NavBar";
import { BrowserView, MobileView } from "react-device-detect";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { validate } from "react-email-validator";
import { Button } from "@mui/material";
import { withTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faIR } from 'date-fns/locale'
import "./DatePickerInput.css";

class RegisterForm extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            username: null,
            password: null,
            confirm_password: null,
            birthday: null,
            first_name: null,
            last_name: null,
            religious: null,
            religious_type: 0,
            degree: null,
            nationality: null,
            major: null,
            gender: null,
            referral: null,
            mobile: null,
            mobile_referral: null,
            email_referral: null,
            isBack: false,
            did_confirm_for_sharing_data: false,
            isSuccessful: false,
            selectedDate: null,
            email: null,
            hasReferral: false
        };
        this.first_nameref = React.createRef();
        this.inputRefs = {
            major: React.createRef(),
            nationality: React.createRef(),
            password: React.createRef(),
            confirm_password: React.createRef(),
            username: React.createRef(),
            degree: React.createRef(),
            gender: React.createRef(),
            email: React.createRef(),
            religious: React.createRef(),
            last_name: React.createRef(),
            first_name: React.createRef(),
            referral: React.createRef(),
            mobile: React.createRef(),
            mobile_referral: React.createRef(),
            email_referral: React.createRef(),
            did_confirm_for_sharing_data: React.createRef(),
            birthday: React.createRef(),
        };


        this.gender_lst = [
            { value: 0, label: 'زن' },
            { value: 1, label: 'مرد' },
        ]

        this.gender_lst_en = [
            { value: 0, label: 'Female' },
            { value: 1, label: 'Male' },
        ]

        this.religious_lst = [
            { value: 1, label: 'اسلام' },
            { value: 2, label: 'مسیحی' }
        ]

        this.religious_lst_en = [
            { value: 1, label: 'Islam' },
            { value: 2, label: 'Christian' }
        ]

        this.religious_type_lst = [
            { value: 1, label: 'شیعه' },
            { value: 2, label: 'سنی' }
        ]

        this.religious_type_lst_en = [
            { value: 1, label: 'Shia' },
            { value: 2, label: 'Sunni' }
        ]

        this.degree_lst = [
            { value: 0, label: 'بدون مدرک' },
            { value: 1, label: 'سیکل' },
            { value: 2, label: 'دیپلم' },
            { value: 3, label: 'کاردانی' },
            { value: 4, label: 'لیسانس' },
            { value: 5, label: 'فوق لیسانس' },
            { value: 6, label: 'دکترا' },
            { value: 7, label: 'فوق دکترا' },
        ]

        this.degree_lst_en = [
            { value: 0, label: 'Other' },
            { value: 1, label: 'High School' },
            { value: 2, label: 'diploma' },
            // { value: 3, label: 'کاردانی' },
            { value: 4, label: 'Bachelor\'s degree' },
            { value: 5, label: 'Master\'s degree' },
            { value: 6, label: 'Ph.D.' },
            { value: 7, label: 'PostDoc' },
        ]

        this.nation_lst = [
            { value: 0, label: 'ایران' },
            { value: 4, label: 'انگلستان' },
            { value: 1, label: 'افغانستان' },
            { value: 2, label: 'آذربایجان' },
            { value: 3, label: 'پاکستان' },
        ]

        this.nation_lst_en = [
            { value: 0, label: 'Iran' },
            { value: 1, label: 'Afghanistan' },
            { value: 2, label: 'Azerbaijan' },
            { value: 3, label: 'Pakistan' },
            { value: 4, label: 'United Kingdom' },
        ]

    }

    chooseLang() {
        var l = localStorage.getItem("lang")
        this.languageChangeListener = () => {
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const { setLanguage } = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
        if (localStorage.getItem("profile") == null) {
            this.getUserProfile()
        }
    }

    getUserProfile() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)
            .then((response) => {
                if (!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify({
                        is_registration_complete: response.data.is_registration_complete,
                        is_seen_videos: response.data.is_seen_videos,
                        is_verified: response.data.is_verified
                    }));
                    this.props.user_profile({
                        is_registration_complete: response.data.is_registration_complete,
                        is_seen_videos: response.data.is_seen_videos,
                        is_verified: response.data.is_verified
                    })
                    this.setState({
                        username: this.props.username,
                        password: this.props.password,
                        birthday: null,
                        first_name: null,
                        last_name: null,
                        religious: null,
                        religious_type: 0,
                        degree: null,
                        nationality: null,
                        major: null,
                        isBack: false,
                        did_confirm_for_sharing_data: false,
                        showVideo: false,
                        requestOptions: null
                    });
                }

            })
            .catch((error) => {


            });
    }

    mobileView() {
        if (this.props.i18n.language === 'en') {
            if (this.state.isBack) {
                return (
                    <div>
                        <Authentication />
                    </div>
                );
            } else {
                if (this.state.isSuccessful) {
                    return (
                        <body className="text-right bg-body ltr">
                            <div className={'container-fluid'}>

                                <MDBRow className="mt-5 pt-4"></MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>
                                        <img width={200} className={'img-fluid'} src="images/success-reg.png" />
                                    </div>
                                </MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg')}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg2')}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg3')}
                                        </p>
                                    </div>
                                </MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>
                                        <Button
                                            className={'mt-3'}
                                            onClick={(v) => this.backAfterSuccess(v)}
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#C4C4C4",
                                                fontSize: "15px",
                                                color: "#fff",
                                                ":hover": {
                                                    bgcolor: "#355070",
                                                    color: "#fff",
                                                }
                                            }}
                                        >
                                            {this.props.i18n.t('back_btn')}
                                        </Button>
                                    </div>
                                </MDBRow>
                                <MDBRow className="mt-5 pt-4"></MDBRow>
                                <Footer
                                    isEnglish={true}
                                />
                            </div>
                        </body>
                    );
                }
            }
            return (

                <body className="text-left bg-body ltr">
                    <div className={'container-fluid'}>
                        <ToastContainer />
                        <Nav />
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow
                            className={'m-1 text-center align-items-center align-self-center justify-content-md-center'}>

                            <MDBCard alighment="center">
                                <MDBCardBody>
                                    <div className={'text-left m-1'} style={{ fontWeight: 'bold' }}>
                                        <label>{this.props.i18n.t('register_btn')}</label>
                                    </div>

                                    <div className="form-check p-2 text-left ">
                                        <label>{this.props.i18n.t('name')}</label>
                                        <input type="text" id="form12" ref={this.inputRefs.first_name} name='first_name' className="form-control text-left"
                                            onChange={(v) => this.firstNameValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-left">
                                        <label>{this.props.i18n.t('family')}</label>
                                        <input type="text" id="form12" ref={this.inputRefs.last_name} name='last_name' className="form-control text-left"
                                            onChange={(v) => this.lastNameValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-left">
                                        <label>{this.props.i18n.t('mobile')}</label>
                                        <input type="number" id="form12" ref={this.inputRefs.mobile} name='mobile' className="form-control text-left"
                                            onChange={(v) => this.mobileValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-left ">
                                        <label>{this.props.i18n.t('email_address')}</label>
                                        <input type="email" id="form12" ref={this.inputRefs.email} name="email" className="form-control text-left"
                                            onChange={(v) => this.emailInputValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-left ">
                                        <label>{this.props.i18n.t('gender')}</label>
                                        <div ref={this.inputRefs.gender} >
                                            <Select
                                                options={this.gender_lst_en}
                                                onChange={value => this.genderValue(value)}
                                                placeholder=""
                                            /></div>
                                    </div>

                                    <div className="form-check p-2 text-left ">
                                        <label>{this.props.i18n.t('birthday2')}</label>
                                        <br />
                                        <div ref={this.inputRefs.birthday} >
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                wrapperClassName="datePicker"
                                                className="datePicker form-control"
                                                selected={this.state.selectedDate}
                                                onChange={(v) => this.birthdayValue(v)}
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={50}
                                                dropdownMode="select"
                                            /></div>

                                    </div>

                                    <div className="form-check p-2 text-left ">
                                        <label>{this.props.i18n.t('nationality_title')}</label>
                                        <div ref={this.inputRefs.nationality}>
                                            <Select
                                                options={this.nation_lst_en}
                                                onChange={value => this.nationalityValue(value)}
                                                placeholder={' '}
                                            /></div>
                                    </div>


                                    <div className="form-check p-2 text-left ">
                                        <label>{this.props.i18n.t('qualification_title')}</label>
                                        <div ref={this.inputRefs.degree}>
                                            <Select
                                                options={this.degree_lst_en}
                                                onChange={value => this.degreeValue(value)}
                                                placeholder=""
                                            /></div>

                                    </div>


                                    <div className="form-check p-2 text-left ">
                                        <label>{this.props.i18n.t('major')}</label>
                                        <input type="text" id="form12" ref={this.inputRefs.major} ame='major' className="form-control text-left"
                                            onChange={(v) => this.majorValue(v)} placeholder="" />
                                    </div>


                                    <div className="form-check p-2 text-left ">
                                        <label>{this.props.i18n.t('religion_title')}</label>
                                        <div ref={this.inputRefs.religious}>
                                            <Select
                                                options={this.religious_lst_en}
                                                onChange={value => this.religiousValue(value)}
                                                placeholder=""
                                            /></div>

                                    </div>

                                    <div className="form-check p-2 text-left " id={'faith-div'}>
                                        <label>{this.props.i18n.t('religious_type')}</label>
                                        <div ref={this.inputRefs.religious_type}>
                                            <Select
                                                options={this.religious_type_lst_en}
                                                onChange={value => this.religious_typeValue(value)}
                                                placeholder=""
                                            /></div>
                                    </div>


                                    <div className="form-check p-2 text-left" >
                                        <label>{this.props.i18n.t('does_referral')}


                                            <span style={{ marginLeft: '30px' }}>
                                                <input
                                                    type="radio"
                                                    name="referralRadio"
                                                    value="no"
                                                    checked={this.state.hasReferral === false}
                                                    onChange={this.handleReferralChange}
                                                /> {this.props.i18n.t('No')}
                                            </span>
                                            <span style={{ marginLeft: '30px' }}>
                                                <input
                                                    type="radio"
                                                    name="referralRadio"
                                                    value="yes"
                                                    checked={this.state.hasReferral === true}
                                                    onChange={this.handleReferralChange}
                                                /> {this.props.i18n.t('Yes')}
                                            </span>
                                        </label>
                                    </div>


                                    {this.state.hasReferral ? (
                                        <div style={{ backgroundColor: '#FCFCFC', margin: '10px 5px', padding: '10px', borderRadius: '10px', borderColor: '#828282', borderStyle: 'solid' }}>
                                            <div className="form-check p-2 text-left" >
                                                <label style={{ marginLeft: '5px' }}>{this.props.i18n.t('referral')}</label>
                                                <center>
                                                    <input
                                                        type="text"
                                                        id="form12"
                                                        ref={this.inputRefs.referral}
                                                        name='referral'
                                                        className="form-control"
                                                        style={{ width: '95%' }}
                                                        onChange={(v) => this.referralValue(v)}
                                                    />
                                                </center>
                                            </div>


                                            <div className="form-check p-2 text-left" >
                                                <label style={{ marginLeft: '5px' }}>{this.props.i18n.t('referral_number')}</label>
                                                <center>
                                                    <input
                                                        type="number"
                                                        id="form12"
                                                        ref={this.inputRefs.mobile_referral}
                                                        name='mobile'
                                                        className="form-control"
                                                        style={{ width: '95%' }}
                                                        onChange={(v) => this.mobileRefferalValue(v)}
                                                    />
                                                </center>
                                            </div>

                                            <div className="form-check p-2 text-left">
                                                <label style={{ marginLeft: '5px' }}>{this.props.i18n.t('referral_email')}</label>
                                                <center>
                                                    <input
                                                        type="email"
                                                        id="form12"
                                                        ref={this.inputRefs.email_referral}
                                                        name='email'
                                                        className="form-control"
                                                        style={{ width: '95%' }}
                                                        onChange={(v) => this.emailRefferalValue(v)}
                                                    />
                                                </center>
                                            </div>
                                        </div>
                                    ) : (
                                        <hr style={{ border: '1px solid #000000', opacity: '100', margin: '15px 15px', marginBottom: '15px' }} />
                                    )}



                                    {/* <div className="form-check p-2 text-left">
                                        <label>{this.props.i18n.t('referral')}</label>
                                        <input type="text" id="form12" ref={this.inputRefs.referral} name='referral' className="form-control text-left"
                                            onChange={(v) => this.referralValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-left">
                                        <label>{this.props.i18n.t('referral_number')}</label>
                                        <input type="number" id="form12" ref={this.inputRefs.mobile_referral} name='mobile' className="form-control text-left"
                                            onChange={(v) => this.mobileRefferalValue(v)} />
                                    </div>
                                    <div className="form-check p-2 text-left">
                                        <label>{this.props.i18n.t('referral_email')}</label>
                                        <input type="email" id="form12" ref={this.inputRefs.email_referral} name='email' className="form-control text-left"
                                            onChange={(v) => this.emailRefferalValue(v)} />
                                    </div> */}

                                    <div className="form-check p-2 text-left ">
                                        <label>{this.props.i18n.t('username')}</label>
                                        <input type="text" id="form12" ref={this.inputRefs.username} name="username" className="form-control text-left"
                                            onChange={(v) => this.usernameInputValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-left">
                                        <label>{this.props.i18n.t('password_title')}</label>
                                        <input type="password" id="form12" ref={this.inputRefs.password} name='password'
                                            className="form-control text-left"
                                            onChange={(v) => this.passwordInputValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-left">
                                        <label>{this.props.i18n.t('password_confirm')}</label>
                                        <input type="password" id="form12" ref={this.inputRefs.confirm_password} name='password'
                                            className="form-control text-left"
                                            onChange={(v) => this.confirmPasswordInputValue(v)} />
                                    </div>

                                    <div className="p-1 m-1">
                                        <input className="form-check-input m-1" ref={this.inputRefs.did_confirm_for_sharing_data}
                                            value={this.state.did_confirm_for_sharing_data}
                                            onClick={(v) => this.checkBox(v)} type="checkbox" value=""
                                            id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            <p style={{ fontSize: '10px' }}> {this.props.i18n.t('confirm_sharing_data')} </p>
                                        </label>
                                    </div>

                                    <MDBRow
                                        className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                        <div>
                                            <button className="btn w-50 mt-3"
                                                style={{ backgroundColor: '#355070', color: '#fff' }}
                                                onClick={(v) => this.registerClick(v)}>
                                                {this.props.i18n.t('register_btn')}
                                            </button>
                                        </div>
                                    </MDBRow>

                                </MDBCardBody>
                            </MDBCard>
                            <div className={'mt-3'}>
                                <label style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold'
                                }}>{this.props.i18n.t('register_an_account')}</label>

                                <button onClick={(v) => this.backBTN(v)} style={{
                                    fontSize: '15px',
                                    marginRight: '5px',
                                    border: 'none',
                                    background: 'none',
                                    color: 'blue',
                                    fontWeight: 'bold'
                                }}>{this.props.i18n.t('sign_in_now')}</button>


                            </div>

                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <Footer
                            isEnglish={true}
                        />
                    </div>
                </body>

            );
        } else {
            registerLocale('faIR', faIR)
            if (this.state.isBack) {
                return (
                    <div>
                        <Authentication />
                    </div>
                );
            } else {
                if (this.state.isSuccessful) {
                    return (
                        <body className="text-right bg-body" dir="rtl">
                            <div className={'container-fluid'}>

                                <MDBRow className="mt-5 pt-4"></MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>
                                        <img width={200} className={'img-fluid'} src="images/success-reg.png" />
                                    </div>
                                </MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg')}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg2')}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg3')}
                                        </p>
                                    </div>
                                </MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>
                                        <Button
                                            className={'mt-3'}
                                            onClick={(v) => this.backAfterSuccess(v)}
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#C4C4C4",
                                                fontSize: "15px",
                                                color: "#fff",
                                                ":hover": {
                                                    bgcolor: "#355070",
                                                    color: "#fff",
                                                }
                                            }}
                                        >
                                            {this.props.i18n.t('back_btn')}
                                        </Button>
                                    </div>
                                </MDBRow>
                                <MDBRow className="mt-5 pt-4"></MDBRow>
                                <Footer
                                    isEnglish={this.props.i18n.language === 'en'}
                                />
                            </div>
                        </body>
                    );
                }
            }
            return (

                <body className="text-right bg-body" dir="rtl">
                    <div className={'container-fluid'}>
                        <ToastContainer />
                        <Nav />
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow
                            className={'m-1 text-center align-items-center align-self-center justify-content-md-center'}>

                            <MDBCard alighment="center">
                                <MDBCardBody>
                                    <div className={'text-right m-1'} style={{ fontWeight: 'bold' }}>
                                        <label>{this.props.i18n.t('register_btn')}</label>
                                    </div>

                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('name')}</label>
                                        <input type="text" id="form12" ref={this.inputRefs.first_name} name='first_name' className="form-control"
                                            onChange={(v) => this.firstNameValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('family')}</label>
                                        <input type="text" id="form12" ref={this.inputRefs.last_name} name='last_name' className="form-control"
                                            onChange={(v) => this.lastNameValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('mobile')}</label>
                                        <input type="number" id="form12" ref={this.inputRefs.mobile} name='mobile' className="form-control"
                                            onChange={(v) => this.mobileValue(v)} />
                                    </div>


                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('email_address')}</label>
                                        <input type="email" id="form12" ref={this.inputRefs.email} name="email" className="form-control"
                                            onChange={(v) => this.emailInputValue(v)} />
                                    </div>


                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('gender')}</label>
                                        <div ref={this.inputRefs.gender} >
                                            <Select
                                                options={this.gender_lst}
                                                onChange={value => this.genderValue(value)}
                                                placeholder=""
                                            /></div>
                                    </div>
 

                                    <div className="form-check p-2 text-right w-100">
                                        <label>{this.props.i18n.t('birthday2')}</label>
                                        <br />
                                        <center>
                                            <div ref={this.inputRefs.birthday} >
                                                <DatePicker
                                                    format="yyyy-MM-dd"
                                                    wrapperClassName="datePicker"
                                                    className="datePicker form-control"
                                                    selected={this.state.selectedDate}
                                                    onChange={(v) => this.birthdayValue(v)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    dropdownMode="select"
                                                    locale='faIR'
                                                /></div>
                                        </center>

                                    </div>


                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('nationality_title')}</label>
                                        <div ref={this.inputRefs.nationality}>
                                            <Select
                                                options={this.nation_lst}
                                                onChange={value => this.nationalityValue(value)}
                                                placeholder={' '}
                                            /></div>
                                    </div>

 
                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('qualification_title')}</label>
                                        <div ref={this.inputRefs.degree}>
                                            <Select
                                                options={this.degree_lst}
                                                onChange={value => this.degreeValue(value)}
                                                placeholder=""
                                            /></div>

                                    </div>



                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('major')}</label>
                                        <input type="text" id="form12" ref={this.inputRefs.major} name='major' className="form-control"
                                            onChange={(v) => this.majorValue(v)} placeholder="" />
                                    </div>


                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('religion_title')}</label>
                                        <div ref={this.inputRefs.religious}>
                                            <Select
                                                options={this.religious_lst}
                                                onChange={value => this.religiousValue(value)}
                                                placeholder=""
                                            /></div>

                                    </div>

                                    <div className="form-check p-2 text-right " id={'faith-div'}>
                                        <label>{this.props.i18n.t('religious_type')}</label>
                                        <div ref={this.inputRefs.religious_type}>
                                            <Select
                                                options={this.religious_type_lst}
                                                onChange={value => this.religious_typeValue(value)}
                                                placeholder=""
                                            /></div>
                                    </div>
                   

                                    {/* <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('referral')}</label>
                                        <input type="text" id="form12" ref={this.inputRefs.referral} name='referral' className="form-control"
                                            onChange={(v) => this.referralValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('referral_number')}</label>
                                        <input type="number" id="form12" ref={this.inputRefs.mobile_referral} name='mobile' className="form-control"
                                            onChange={(v) => this.mobileRefferalValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('referral_email')}</label>
                                        <input type="email" id="form12" ref={this.inputRefs.mobile_email} name='email' className="form-control"
                                            onChange={(v) => this.emailRefferalValue(v)} />
                                    </div> */}





                                    <div className="form-check p-2 text-right" >
                                        <label>{this.props.i18n.t('does_referral')}


                                            <span style={{ marginRight: '30px' }}>
                                                <input
                                                    type="radio"
                                                    name="referralRadio"
                                                    value="no"
                                                    checked={this.state.hasReferral === false}
                                                    onChange={this.handleReferralChange}
                                                /> {this.props.i18n.t('No')}
                                            </span>
                                            <span style={{ marginRight: '30px' }}>
                                                <input
                                                    type="radio"
                                                    name="referralRadio"
                                                    value="yes"
                                                    checked={this.state.hasReferral === true}
                                                    onChange={this.handleReferralChange}
                                                /> {this.props.i18n.t('Yes')}
                                            </span>
                                        </label>
                                    </div>


                                    {this.state.hasReferral ? (
                                        <div style={{ backgroundColor: '#FCFCFC', margin: '10px 5px', padding: '10px', borderRadius: '10px', borderColor: '#828282', borderStyle: 'solid' }}>
                                            <div className="form-check p-2 text-right">
                                                <label>{this.props.i18n.t('referral')}</label>
                                                <center>
                                                    <input
                                                        type="text"
                                                        id="form12"
                                                        ref={this.inputRefs.referral}
                                                        name='referral'
                                                        className="form-control"
                                                        style={{ width: '95%' }}
                                                        onChange={(v) => this.referralValue(v)}
                                                    />
                                                </center>
                                            </div>


                                            <div className="form-check p-2 text-right" >
                                                <label>{this.props.i18n.t('referral_number')}</label>
                                                <center>
                                                    <input
                                                        type="number"
                                                        id="form12"
                                                        ref={this.inputRefs.mobile_referral}
                                                        name='mobile'
                                                        className="form-control"
                                                        style={{ width: '95%' }}
                                                        onChange={(v) => this.mobileRefferalValue(v)}
                                                    />
                                                </center>
                                            </div>

                                            <div className="form-check p-2 text-right">
                                                <label>{this.props.i18n.t('referral_email')}</label>
                                                <center>
                                                    <input
                                                        type="email"
                                                        id="form12"
                                                        ref={this.inputRefs.email_referral}
                                                        name='email'
                                                        className="form-control"
                                                        style={{ width: '95%' }}
                                                        onChange={(v) => this.emailRefferalValue(v)}
                                                    />
                                                </center>
                                            </div>
                                        </div>
                                    ) : (
                                        <hr style={{ border: '1px solid #000000', opacity: '100', margin: '15px 15px', marginBottom: '15px' }} />
                                    )}





                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('username')}</label>
                                        <input type="text" id="form12" ref={this.inputRefs.username} name="username" className="form-control"
                                            onChange={(v) => this.usernameInputValue(v)} />
                                    </div>



                                    <div className="form-check p-2 text-right">
                                        <label>{this.props.i18n.t('password_title')}</label>
                                        <input type="password" id="form12" ref={this.inputRefs.password} name='password' className="form-control"
                                            onChange={(v) => this.passwordInputValue(v)} />
                                    </div>

                                    <div className="form-check p-2 text-right">
                                        <label>{this.props.i18n.t('password_confirm')}</label>
                                        <input type="password" id="form12" ref={this.inputRefs.confirm_password} name='password' className="form-control"
                                            onChange={(v) => this.confirmPasswordInputValue(v)} />
                                    </div>

                                    <div className="p-1 m-1">
                                        <input className="form-check-input m-1" ref={this.inputRefs.did_confirm_for_sharing_data}
                                            value={this.state.did_confirm_for_sharing_data}
                                            onClick={(v) => this.checkBox(v)} type="checkbox" value=""
                                            id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            <p style={{ fontSize: '10px' }}> {this.props.i18n.t('confirm_sharing_data')} </p>
                                        </label>
                                    </div>

                                    <MDBRow
                                        className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                        <div>
                                            <button className="btn w-50 mt-3"
                                                style={{ backgroundColor: '#355070', color: '#fff' }}
                                                onClick={(v) => this.registerClick(v)}>
                                                {this.props.i18n.t('register_btn')}
                                            </button>
                                        </div>
                                    </MDBRow>

                                </MDBCardBody>
                            </MDBCard>
                            <div className={'mt-3'}>
                                <label style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold'
                                }}>{this.props.i18n.t('register_an_account')}</label>

                                <button onClick={(v) => this.backBTN(v)} style={{
                                    fontSize: '15px',
                                    marginRight: '5px',
                                    border: 'none',
                                    background: 'none',
                                    color: 'blue',
                                    fontWeight: 'bold'
                                }}>{this.props.i18n.t('sign_in_now')}</button>


                            </div>

                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <Footer
                            isEnglish={this.props.i18n.language === 'en'}
                        />
                    </div>
                </body>

            );
        }
    }

    desktopView() {
        if (this.props.i18n.language === 'en') {
            if (this.state.isBack) {
                return (
                    <div>
                        <Authentication />
                    </div>
                );
            } else {
                if (this.state.isSuccessful) {
                    return (
                        <body className="text-right bg-body ltr" >
                            <div className={'container-fluid'}>

                                <MDBRow className="mt-5 pt-4"></MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>
                                        <img width={200} className={'img-fluid'} src="images/success-reg.png" />
                                    </div>
                                </MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg')}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg2')}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg3')}
                                        </p>
                                    </div>
                                </MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>

                                        <Button
                                            className={'mt-3'}
                                            onClick={(v) => this.backAfterSuccess(v)}
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#C4C4C4",
                                                fontSize: "15px",
                                                fontFamily: 'IRANSansX',
                                                color: "#fff",
                                                ":hover": {
                                                    bgcolor: "#355070",
                                                    color: "#fff",
                                                }
                                            }}
                                        >
                                            {this.props.i18n.t('back_btn')}
                                        </Button>
                                    </div>
                                </MDBRow>
                                <MDBRow className="mt-5 pt-4"></MDBRow>
                                <Footer
                                    isEnglish={true}
                                />
                            </div>
                        </body>
                    );
                }
            }
            return (

                <body className="text-right bg-body ltr" >
                    <div className={'container-fluid'}>
                        <ToastContainer />
                        <Nav />
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                            <MDBCol size={"4"} className="justify-content-center align-items-center h-100 text-center m-1">
                                <MDBCard alighment="center">
                                    <MDBCardBody>
                                        <div className={'text-left m-1'} style={{ fontWeight: 'bold' }}>
                                            <label>{this.props.i18n.t('register_btn')}</label>
                                        </div>

                                        <div className="form-check p-2 text-left "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('name')}</label>
                                            <center>
                                                <input type="text" id="form12" ref={this.inputRefs.first_name} name='first_name'
                                                    className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.firstNameValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-left "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('family')}</label>
                                            <center>
                                                <input type="text" id="form12" ref={this.inputRefs.last_name} name='last_name' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.lastNameValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-left "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('mobile')}</label>
                                            <center>
                                                <input type="number" id="form12" ref={this.inputRefs.mobile} name='mobile' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.mobileValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-left "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label
                                                style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('email_address')}</label>
                                            <center>
                                                <input type="email" id="form12" ref={this.inputRefs.email} name="email" className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.emailInputValue(v)} />
                                            </center>
                                        </div>
                    
                    
                                        <div className="form-check p-2 text-left "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('gender')}</label>
                                            <center>
                                                <div ref={this.inputRefs.gender} style={{ width: '95%' }}>
                                                    <Select
                                                        options={this.gender_lst_en}
                                                        onChange={value => this.genderValue(value)}
                                                        placeholder=""
                                                    />
                                                </div>
                                            </center>
                                        </div>


                                        <MDBRow>
                                        <MDBCol size={"6"}>
                                                <div className="form-check p-2 text-left " style={{ marginLeft: '45px' }}>
                                                    <label>{this.props.i18n.t('birthday2')}</label>
                                                    <div ref={this.inputRefs.birthday} >
                                                        <DatePicker
                                                            format="yyyy-MM-dd"
                                                            selected={this.state.selectedDate}
                                                            className={'form-control'}
                                                            onChange={(v) => this.birthdayValue(v)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            yearDropdownItemNumber={50}
                                                            dropdownMode="select"
                                                        /></div>


                                                </div>
                                            </MDBCol>
                                            <MDBCol size={'6'}>
                                                <div className="form-check p-2 text-left " style={{ marginRight: '45px' }}>
                                                    <label>{this.props.i18n.t('nationality_title')}</label>
                                                    <div ref={this.inputRefs.nationality}>
                                                        <Select
                                                            options={this.nation_lst_en}
                                                            onChange={value => this.nationalityValue(value)}
                                                            placeholder={' '}
                                                        /></div>
                                                </div>
                                            </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                            <MDBCol size={"6"}>
                                                <div className="form-check p-2 text-left " style={{ marginLeft: '45px' }}>
                                                    <label>{this.props.i18n.t('qualification_title')}</label>
                                                    <div ref={this.inputRefs.degree}>
                                                        <Select
                                                            options={this.degree_lst_en}
                                                            onChange={value => this.degreeValue(value)}
                                                            placeholder=""
                                                        /></div>

                                                </div>
                                            </MDBCol>

                                            <MDBCol size={'6'}>
                                                <div className="form-check p-2 text-left " style={{ marginRight: '45px' }}>
                                                    <label>{this.props.i18n.t('major')}</label>
                                                    <input type="text" ref={this.major} id="form12" ref={this.inputRefs.major} name='major' className="form-control"
                                                        onChange={(v) => this.majorValue(v)} placeholder="" />
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol size={"6"}>
                                                <div className="form-check p-2 text-left " style={{ marginLeft: '45px' }}>
                                                    <label>{this.props.i18n.t('religion_title')}</label>
                                                    <div ref={this.inputRefs.religious}>
                                                        <Select
                                                            options={this.religious_lst_en}
                                                            onChange={value => this.religiousValue(value)}
                                                            placeholder=""
                                                        /></div>

                                                </div>
                                            </MDBCol>
                                            <MDBCol size={'6'}>
                                                <div className="form-check p-2 text-left " id={'faith-div'}
                                                    style={{ marginRight: '45px' }}>
                                                    <label>{this.props.i18n.t('religious_type')}</label>
                                                    <div ref={this.inputRefs.religious_type}>
                                                        <Select
                                                            options={this.religious_type_lst_en}
                                                            onChange={value => this.religious_typeValue(value)}
                                                            placeholder=""
                                                        /></div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>

  

                                        <div className="form-check p-2 text-left" style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingLeft: '.8em', display: 'inline-flex', alignItems: 'center' }}>{this.props.i18n.t('does_referral')}


                                                <span style={{ marginLeft: '150px' }}>
                                                    <input
                                                        type="radio"
                                                        name="referralRadio"
                                                        value="no"
                                                        checked={this.state.hasReferral === false}
                                                        onChange={this.handleReferralChange}
                                                    /> {this.props.i18n.t('No')}
                                                </span>
                                                <span style={{ marginLeft: '50px' }}>
                                                    <input
                                                        type="radio"
                                                        name="referralRadio"
                                                        value="yes"
                                                        checked={this.state.hasReferral === true}
                                                        onChange={this.handleReferralChange}
                                                    /> {this.props.i18n.t('Yes')}
                                                </span>
                                            </label>
                                        </div>


                                        {this.state.hasReferral ? (
                                            <div style={{ backgroundColor: '#FCFCFC', margin: '0px 45px', padding: '20px', borderRadius: '10px', borderColor: '#828282', borderStyle: 'solid' }}>
                                                <div className="form-check p-2 text-left" style={{ marginRight: '35px', marginLeft: '35px' }}>
                                                    <label style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('referral')}</label>
                                                    <center>
                                                        <input
                                                            type="text"
                                                            id="form12"
                                                            ref={this.inputRefs.referral}
                                                            name='referral'
                                                            className="form-control"
                                                            style={{ width: '95%' }}
                                                            onChange={(v) => this.referralValue(v)}
                                                        />
                                                    </center>
                                                </div>

                                                <div className="form-check p-2 text-left" style={{ marginRight: '35px', marginLeft: '35px' }}>
                                                    <label style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('referral_number')}</label>
                                                    <center>
                                                        <input
                                                            type="number"
                                                            id="form12"
                                                            ref={this.inputRefs.mobile_referral}
                                                            name='mobile'
                                                            className="form-control"
                                                            style={{ width: '95%' }}
                                                            onChange={(v) => this.mobileRefferalValue(v)}
                                                        />
                                                    </center>
                                                </div>

                                                <div className="form-check p-2 text-left" style={{ marginRight: '35px', marginLeft: '35px' }}>
                                                    <label style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('referral_email')}</label>
                                                    <center>
                                                        <input
                                                            type="email"
                                                            id="form12"
                                                            ref={this.inputRefs.email_referral}
                                                            name='email'
                                                            className="form-control"
                                                            style={{ width: '95%' }}
                                                            onChange={(v) => this.emailRefferalValue(v)}
                                                        />
                                                    </center>
                                                </div>
                                            </div>
                                        ) : (
                                            <hr style={{ border: '1px solid #000000', opacity: '100', margin: '15px 45px', marginBottom: '15px' }} />
                                        )}
                                        {/* <div className="form-check p-2 text-left "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('referral')}</label>
                                            <center>
                                                <input type="text" id="form12" ref={this.inputRefs.referral} name='referral' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.referralValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-left "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('referral_number')}</label>
                                            <center>
                                                <input type="number" id="form12" ref={this.inputRefs.mobile_referral} name='mobile' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.mobileRefferalValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-left "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('referral_email')}</label>
                                            <center>
                                                <input type="email" id="form12" ref={this.inputRefs.email_referral} name='email' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.emailRefferalValue(v)} />
                                            </center>
                                        </div> */}



                                        <div className="form-check p-2 text-left "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label
                                                style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('username')}</label>
                                            <center>
                                                <input type="text" id="form12" ref={this.inputRefs.username} name="username" className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.usernameInputValue(v)} />
                                            </center>
                                        </div>



                                        <div className="form-check p-2 text-left"
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label
                                                style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('password_title')}</label>
                                            <center>
                                                <input type="password" id="form12" ref={this.inputRefs.password} name='password' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.passwordInputValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-left"
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label
                                                style={{ paddingLeft: '.8em' }}>{this.props.i18n.t('password_confirm')}</label>
                                            <center>
                                                <input type="password" id="form12" ref={this.inputRefs.confirm_password} name='password' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.confirmPasswordInputValue(v)} />
                                            </center>
                                        </div>

                                        <div className="p-1 m-1">
                                            <input className="form-check-input m-1" ref={this.inputRefs.did_confirm_for_sharing_data}
                                                value={this.state.did_confirm_for_sharing_data}
                                                onClick={(v) => this.checkBox(v)} type="checkbox" value=""
                                                id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                <p> {this.props.i18n.t('confirm_sharing_data')} </p>
                                            </label>
                                        </div>


                                        <MDBRow
                                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                            <div>
                                                <Button
                                                    className={'w-25 mt-3'}
                                                    onClick={(v) => this.registerClick(v)}
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "#C4C4C4",
                                                        fontSize: "15px",
                                                        fontFamily: 'IRANSansX',
                                                        color: "#fff",
                                                        ":hover": {
                                                            bgcolor: "#355070",
                                                            color: "#fff",
                                                        }
                                                    }}
                                                >
                                                    {this.props.i18n.t('contact_us_form_send_btn')}
                                                </Button>
                                            </div>
                                        </MDBRow>

                                    </MDBCardBody>
                                </MDBCard>
                                <div className={'mt-3'}>
                                    <label style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold'
                                    }}>{this.props.i18n.t('register_an_account')}</label>

                                    <button onClick={(v) => this.backBTN(v)} style={{
                                        fontSize: '15px',
                                        marginRight: '5px',
                                        border: 'none',
                                        fontFamily: 'IRANSansX',
                                        background: 'none',
                                        color: 'blue',
                                        fontWeight: 'bold'
                                    }}>{this.props.i18n.t('sign_in_now')}</button>


                                </div>
                            </MDBCol>
                            <MDBCol size={"5"} className={'m-4'}>
                                <img className={'img-fluid'} src="images/register.png" />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <Footer
                            isEnglish={true}
                        />
                    </div>
                </body>

            );
        } else {
            registerLocale('faIR', faIR)
            if (this.state.isBack) {
                return (
                    <div>
                        <Authentication />
                    </div>
                );
            } else {
                if (this.state.isSuccessful) {
                    return (
                        <body className="text-right bg-body" dir="rtl">
                            <div className={'container-fluid'}>

                                <MDBRow className="mt-5 pt-4"></MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>
                                        <img width={200} className={'img-fluid'} src="images/success-reg.png" />
                                    </div>
                                </MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg')}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg2')}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('registration_msg3')}
                                        </p>
                                    </div>
                                </MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>

                                        <Button
                                            className={'mt-3'}
                                            onClick={(v) => this.backAfterSuccess(v)}
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#C4C4C4",
                                                fontSize: "15px",
                                                color: "#fff",
                                                ":hover": {
                                                    bgcolor: "#355070",
                                                    color: "#fff",
                                                }
                                            }}
                                        >
                                            {this.props.i18n.t('back_btn')}
                                        </Button>
                                    </div>
                                </MDBRow>
                                <MDBRow className="mt-5 pt-4"></MDBRow>
                                <Footer
                                    isEnglish={false}
                                />
                            </div>
                        </body>
                    );
                }
            }
            return (

                <body className="text-right bg-body" dir="rtl">
                    <div className={'container-fluid'}>
                        <ToastContainer />
                        <Nav />
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                            <MDBCol size={"4"} className="justify-content-center align-items-center h-100 text-center m-1">
                                <MDBCard alighment="center">
                                    <MDBCardBody>
                                        <div className={'text-right m-1'} style={{ fontWeight: 'bold' }}>
                                            <label>{this.props.i18n.t('register_btn')}</label>
                                        </div>

                                        <div className="form-check p-2 text-right "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingRight: '.8em' }}>{this.props.i18n.t('name')}</label>
                                            <center>
                                                <input type="text" id="form12" ref={this.inputRefs.first_name} name="first_name" className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.firstNameValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-right "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingRight: '.8em' }}>{this.props.i18n.t('family')}</label>
                                            <center>
                                                <input type="text" id="form12" ref={this.inputRefs.last_name} name='last_name' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.lastNameValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-right "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingRight: '.8em' }}>{this.props.i18n.t('mobile')}</label>
                                            <center>
                                                <input type="number" id="form12" ref={this.inputRefs.mobile} name='mobile' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.mobileValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-right "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label
                                                style={{ paddingRight: '.8em' }}>{this.props.i18n.t('email_address')}</label>
                                            <center>
                                                <input type="email" id="form12" ref={this.inputRefs.email} name="email" className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.emailInputValue(v)} />
                                            </center>
                                        </div>


                                        <div className="form-check p-2 text-right "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingRight: '.8em' }}>{this.props.i18n.t('gender')}</label>
                                            <center>
                                                <div ref={this.inputRefs.gender} style={{ width: '95%' }}>
                                                    <Select
                                                        options={this.gender_lst}
                                                        style={{ width: '95%' }}
                                                        onChange={value => this.genderValue(value)}
                                                        placeholder=""
                                                    />
                                                </div>
                                            </center>
                                        </div>


                                        <MDBRow>
                                        <MDBCol size={"6"}>
                                                <div className="form-check p-2 text-right " style={{ marginRight: '45px' }}>
                                                    <label>{this.props.i18n.t('birthday2')}</label>
                                                    <div ref={this.inputRefs.birthday} >
                                                        <DatePicker
                                                            format="yyyy-MM-dd"
                                                            selected={this.state.selectedDate}
                                                            className={'form-control'}
                                                            onChange={(v) => this.birthdayValue(v)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            yearDropdownItemNumber={50}
                                                            dropdownMode="select"
                                                            locale='faIR'
                                                        /></div>

                                                </div>
                                            </MDBCol>
                                            <MDBCol size={'6'}>
                                                <div className="form-check p-2 text-right " style={{ marginLeft: '45px' }}>
                                                    <label>{this.props.i18n.t('nationality_title')}</label>
                                                    <div ref={this.inputRefs.nationality}>
                                                        <Select
                                                            options={this.nation_lst}
                                                            onChange={value => this.nationalityValue(value)}
                                                            placeholder={' '}
                                                        /></div>
                                                </div>
                                            </MDBCol>
                                            </MDBRow>
                                            <MDBRow>    
                                            <MDBCol size={"6"}>
                                                <div className="form-check p-2 text-right " style={{ marginRight: '45px' }}>
                                                    <label>{this.props.i18n.t('qualification_title')}</label>
                                                    <div ref={this.inputRefs.degree}>
                                                        <Select
                                                            options={this.degree_lst}
                                                            onChange={value => this.degreeValue(value)}
                                                            placeholder=""
                                                        /></div>

                                                </div>
                                            </MDBCol>

                                            <MDBCol size={'6'}>
                                                <div className="form-check p-2 text-right " style={{ marginLeft: '45px' }}>
                                                    <label>{this.props.i18n.t('major')}</label>
                                                    <input type="text" id="form12" ref={this.inputRefs.major} name='major' className="form-control"
                                                        onChange={(v) => this.majorValue(v)} placeholder="" />
                                                </div>
                                            </MDBCol>
                                            </MDBRow>
                                            <MDBRow>   
                                            <MDBCol size={"6"}>
                                                <div className="form-check p-2 text-right " style={{ marginRight: '45px' }}>
                                                    <label>{this.props.i18n.t('religion_title')}</label>
                                                    <div ref={this.inputRefs.religious}>
                                                        <Select
                                                            options={this.religious_lst}
                                                            onChange={value => this.religiousValue(value)}
                                                            placeholder=""
                                                        /></div>

                                                </div>
                                            </MDBCol>
                                            <MDBCol size={'6'}>
                                                <div className="form-check p-2 text-right " ref={this.inputRefs.religious_type} id={'faith-div'}
                                                    style={{ marginLeft: '45px' }}>
                                                    <label>{this.props.i18n.t('religious_type')}</label>
                                                    <div ref={this.inputRefs.religious_type}>
                                                        <Select
                                                            options={this.religious_type_lst}
                                                            onChange={value => this.religious_typeValue(value)}
                                                            placeholder=""
                                                        /> </div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>



                                        <div className="form-check p-2 text-right" style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingLeft: '.8em', display: 'inline-flex', alignItems: 'center' }}>{this.props.i18n.t('does_referral')}


                                                <span style={{ marginRight: '150px' }}>
                                                    <input
                                                        type="radio"
                                                        name="referralRadio"
                                                        value="no"
                                                        checked={this.state.hasReferral === false}
                                                        onChange={this.handleReferralChange}
                                                    /> {this.props.i18n.t('No')}
                                                </span>
                                                <span style={{ marginRight: '50px' }}>
                                                    <input
                                                        type="radio"
                                                        name="referralRadio"
                                                        value="yes"
                                                        checked={this.state.hasReferral === true}
                                                        onChange={this.handleReferralChange}
                                                    /> {this.props.i18n.t('Yes')}
                                                </span>
                                            </label>
                                        </div>


                                        {this.state.hasReferral ? (
                                            <div style={{ backgroundColor: '#FCFCFC', margin: '0px 45px', padding: '20px', borderRadius: '10px', borderColor: '#828282', borderStyle: 'solid' }}>
                                                <div className="form-check p-2 text-right" style={{ marginRight: '35px', marginLeft: '35px' }}>
                                                    <label style={{ paddingRight: '.8em' }}>{this.props.i18n.t('referral')}</label>
                                                    <center>
                                                        <input
                                                            type="text"
                                                            id="form12"
                                                            ref={this.inputRefs.referral}
                                                            name='referral'
                                                            className="form-control"
                                                            style={{ width: '95%' }}
                                                            onChange={(v) => this.referralValue(v)}
                                                        />
                                                    </center>
                                                </div>


                                                <div className="form-check p-2 text-right" style={{ marginRight: '35px', marginLeft: '35px' }}>
                                                    <label style={{ paddingRight: '.8em' }}>{this.props.i18n.t('referral_number')}</label>
                                                    <center>
                                                        <input
                                                            type="number"
                                                            id="form12"
                                                            ref={this.inputRefs.mobile_referral}
                                                            name='mobile'
                                                            className="form-control"
                                                            style={{ width: '95%' }}
                                                            onChange={(v) => this.mobileRefferalValue(v)}
                                                        />
                                                    </center>
                                                </div>

                                                <div className="form-check p-2 text-right" style={{ marginRight: '35px', marginLeft: '35px' }}>
                                                    <label style={{ paddingRight: '.8em' }}>{this.props.i18n.t('referral_email')}</label>
                                                    <center>
                                                        <input
                                                            type="email"
                                                            id="form12"
                                                            ref={this.inputRefs.email_referral}
                                                            name='email'
                                                            className="form-control"
                                                            style={{ width: '95%' }}
                                                            onChange={(v) => this.emailRefferalValue(v)}
                                                        />
                                                    </center>
                                                </div>
                                            </div>
                                        ) : (
                                            <hr style={{ border: '1px solid #000000', opacity: '100', margin: '15px 45px', marginBottom: '15px' }} />
                                        )}
                                        {/* <div className="form-check p-2 text-right "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingRight: '.8em' }}>{this.props.i18n.t('referral')}</label>
                                            <center>
                                                <input type="text" id="form12" ref={this.inputRefs.referral} name='referral' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.referralValue(v)} />
                                            </center>
                                        </div> */}
                                        {/* <div className="form-check p-2 text-right "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingRight: '.8em' }}>{this.props.i18n.t('referral_number')}</label>
                                            <center>
                                                <input type="number" id="form12" ref={this.inputRefs.mobile_referral} name='mobile' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.mobileRefferalValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-right "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label style={{ paddingRight: '.8em' }}>{this.props.i18n.t('referral_email')}</label>
                                            <center>
                                                <input type="email" id="form12" ref={this.inputRefs.email_referral} name='email' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.emailRefferalValue(v)} />
                                            </center>
                                        </div> */}






                                        <div className="form-check p-2 text-right "
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label
                                                style={{ paddingRight: '.8em' }}>{this.props.i18n.t('username')}</label>
                                            <center>
                                                <input type="text" id="form12" ref={this.inputRefs.username} name="username" className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.usernameInputValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-right"
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label
                                                style={{ paddingRight: '.8em' }}>{this.props.i18n.t('password_title')}</label>
                                            <center>
                                                <input type="password" id="form12" ref={this.inputRefs.password} name='password' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.passwordInputValue(v)} />
                                            </center>
                                        </div>

                                        <div className="form-check p-2 text-right"
                                            style={{ marginRight: '35px', marginLeft: '35px' }}>
                                            <label
                                                style={{ paddingRight: '.8em' }}>{this.props.i18n.t('password_confirm')}</label>
                                            <center>
                                                <input type="password" id="form12" ref={this.inputRefs.confirm_password} name='password' className="form-control"
                                                    style={{ width: '95%' }}
                                                    onChange={(v) => this.confirmPasswordInputValue(v)} />
                                            </center>
                                        </div>

                                        <div className="p-1 m-1">
                                            <input className="form-check-input m-1" ref={this.inputRefs.did_confirm_for_sharing_data}
                                                value={this.state.did_confirm_for_sharing_data}
                                                onClick={(v) => this.checkBox(v)} type="checkbox" value=""
                                                id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                <p> {this.props.i18n.t('confirm_sharing_data')} </p>
                                            </label>
                                        </div>


                                        <MDBRow
                                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                            <div>
                                                <Button
                                                    className={'w-25 mt-3'}
                                                    onClick={(v) => this.registerClick(v)}
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "#C4C4C4",
                                                        fontSize: "15px",
                                                        fontFamily: 'IRANSansX',
                                                        color: "#fff",
                                                        ":hover": {
                                                            bgcolor: "#355070",
                                                            color: "#fff",
                                                        }
                                                    }}
                                                >
                                                    {this.props.i18n.t('contact_us_form_send_btn')}
                                                </Button>
                                            </div>
                                        </MDBRow>

                                    </MDBCardBody>
                                </MDBCard>
                                <div className={'mt-3'}>
                                    <label style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold'
                                    }}>{this.props.i18n.t('register_an_account')}</label>

                                    <button onClick={(v) => this.backBTN(v)} style={{
                                        fontSize: '15px',
                                        marginRight: '5px',
                                        border: 'none',
                                        fontFamily: 'IRANSansX',
                                        background: 'none',
                                        color: 'blue',
                                        fontWeight: 'bold'
                                    }}>{this.props.i18n.t('sign_in_now')}</button>


                                </div>
                            </MDBCol>
                            <MDBCol size={"5"} className={'m-4'}>
                                <img className={'img-fluid'} src="images/register.png" />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <Footer
                            isEnglish={false}
                        />
                    </div>
                </body>

            );
        }
    }

    render() {
        return (
            <div>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        );
    }

    usernameInputValue(value) {
        this.state.username = value.target.value
        removeError(this.inputRefs.username);
    }

    emailInputValue(value) {
        this.state.email = value.target.value
        removeError(this.inputRefs.email);
    }

    passwordInputValue(value) {
        this.state.password = value.target.value
        removeError(this.inputRefs.password);
    }

    confirmPasswordInputValue(value) {
        this.state.confirm_password = value.target.value
        removeError(this.inputRefs.confirm_password);
    }

    birthdayValue(value) {
        removeError(this.inputRefs.birthday);
        var D = new Date(value)
        var date = D.getFullYear() + '-' + (D.getMonth() + 1) + '-' + D.getDate()
        console.log(date)
        this.setState({ birthday: date, selectedDate: value })
    }

    firstNameValue(value) {
        this.state.first_name = value.target.value;
        removeError(this.inputRefs.first_name);
    }

    lastNameValue(value) {
        this.state.last_name = value.target.value;
        removeError(this.inputRefs.last_name);
    }

    mobileValue(value) {
        this.state.mobile = value.target.value;
        removeError(this.inputRefs.mobile);
    }

    mobileRefferalValue(value) {
        this.state.mobile_referral = value.target.value;
        removeError(this.inputRefs.mobile_referral);
    }

    emailRefferalValue(value) {
        this.state.email_referral = value.target.value;
        removeError(this.inputRefs.email_referral);
    }

    referralValue(value) {
        this.state.referral = value.target.value;
        removeError(this.inputRefs.referral);
    }

    religiousValue(value) {
        removeError(this.inputRefs.religious);
        if (value['value'] === 2) {
            document.getElementById('faith-div').style.display = 'none'
        } else {
            document.getElementById('faith-div').style.display = 'block'
        }
        this.state.religious = value['value'];
    }

    genderValue(value) {
        removeError(this.inputRefs.gender);
        this.state.gender = value['value'];
    }

    religious_typeValue(value) {
        removeError(this.inputRefs.religious_type);
        this.state.religious_type = value['value'];
    }

    degreeValue(value) {
        this.state.degree = value['value'];


    }

    nationalityValue(value) {
        removeError(this.inputRefs.nationality);
        this.state.nationality = value['value'];
        removeError(this.inputRefs.degree);
    }

    majorValue(value) {
        removeError(this.inputRefs.major);
        this.state.major = value.target.value;
    }

    checkBox(value) {
        this.state.did_confirm_for_sharing_data = !this.state.did_confirm_for_sharing_data
        removeError(this.inputRefs.did_confirm_for_sharing_data);
    }

    registerClick(st) {
        const nullFields = getNullFields(this.state);
        if (nullFields.length > 0) {
            toast.error(this.props.i18n.t('email_password_are_empty_toast'), {
                position: toast.POSITION.TOP_LEFT
            });
            const nullFieldRefs = nullFields.map(field => this.ref);
        } else {
            // Handle form submission logic here
        }
        const nullFieldRefs = nullFields.map(field => this.inputRefs[field]);
        highlightAndFocusInputs(nullFieldRefs);
        if (nullFields.length > 0) nullFieldRefs[0].current.focus();

        if (this.state.major === null ||
            this.state.nationality === null ||
            this.state.password === null ||
            this.state.confirm_password === null ||
            this.state.username === null ||
            this.state.degree === null ||
            this.state.gender === null ||
            this.state.religious === null ||
            this.state.last_name === null ||
            this.state.first_name === null ||
            this.state.mobile === null ||
            // this.state.referral === null ||
            // this.state.mobile_referral === null ||
            // this.state.email_referral === null ||
            this.state.did_confirm_for_sharing_data === false ||
            this.state.birthday === null) {

            toast.error(this.props.i18n.t('contact_us_send_msg_error_toast'), {
                position: toast.POSITION.TOP_LEFT
            });

        } else {
            if (validate(this.state.email)) {
                if (this.state.password === this.state.confirm_password) {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            first_name: this.state.first_name,
                            last_name: this.state.last_name,
                            birthday: this.state.birthday,
                            religious: this.state.religious,
                            gender: this.state.gender,
                            religious_type: this.state.religious_type,
                            degree: this.state.degree,
                            nationality: this.state.nationality,
                            major: this.state.major,
                            password: this.state.password,
                            email: this.state.email,
                            username: this.state.username,
                            referral: this.state.referral,
                            mobile: this.state.mobile,
                            mobile_referral: this.state.mobile_referral,
                            email_referral: this.state.email_referral,
                            did_confirm_for_sharing_data: this.state.did_confirm_for_sharing_data
                        })
                    };

                    fetch('https://uharmonymatch.com/api/v1/register/', requestOptions)
                    // fetch('http://172.40.1.72/api/v1/register/', requestOptions)
                        .then((response) => {
                            if (!response.ok) throw response.status;
                            else return response.json();
                        })
                        .then((response) => {
                            if (response.status === 201) {
                                this.setState({ isSuccessful: true })
                            }
                        })
                        .catch((error) => {

                            if (error === 201) {
                                this.setState({ isSuccessful: true })
                            }
                            if (error === 400) {
                                toast.error(this.props.i18n.t('profile_update_error_toast'), {
                                    position: toast.POSITION.TOP_LEFT
                                });
                            } else {
                                if (error === 409) {
                                    toast.error(this.props.i18n.t('register_error_duplicated_error_toast'), {
                                        position: toast.POSITION.TOP_LEFT
                                    });
                                } else {
                                    if (error === 406) {
                                        toast.error(this.props.i18n.t('register_error_under_18_toast'), {
                                            position: toast.POSITION.TOP_LEFT
                                        });
                                    } else {
                                        if (error === 429) {
                                            toast.error(this.props.i18n.t('register_error_more_request_error_toast'), {
                                                position: toast.POSITION.TOP_LEFT
                                            });
                                        }
                                    }
                                }
                            }

                        });
                } else {
                    this.inputRefs.password.current.classList.add('input-error');
                    this.inputRefs.confirm_password.current.classList.add('input-error');
                    toast.error(this.props.i18n.t('password_and_confirm_password_are_not_same_error_toast'), {
                        position: toast.POSITION.TOP_LEFT
                    });
                }
            } else {
                this.inputRefs.username.current.classList.add('input-error');
                toast.error(this.props.i18n.t('register_username_validation_error_toast'), {
                    position: toast.POSITION.TOP_LEFT
                });
            }
        }
    }

    backBTN() {
        this.setState({
            isBack: true
        })
    }
    handleReferralChange = (event) => {
        this.setState({ hasReferral: event.target.value === 'yes' });
    };

    backAfterSuccess(v) {
        window.location.href = '/'
    }

    stringIsEnglish(str) {
        var ENGLISH = {};
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("").forEach(function (ch) {
            ENGLISH[ch] = true;
        });
        var index;

        for (index = str.length - 1; index >= 0; --index) {
            if (!ENGLISH[str.substring(index, index + 1)]) {
                return false;
            }
        }
        return true;
    }

}

// Function To create a list out of unfilled inputs
function getNullFields(state) {
    const nullFields = [];

    if (state.first_name === null || state.first_name === "") nullFields.push('first_name');
    if (state.last_name === null || state.last_name === "") nullFields.push('last_name');
    if (state.mobile === null || state.mobile === "") nullFields.push('mobile');
    // if (state.mobile_referral === null || state.mobile_referral === "") nullFields.push('mobile_referral');
    // if (state.email_referral === null || state.email_referral === "") nullFields.push('email_referral');
    // if (state.referral === null || state.referral === "") nullFields.push('referral');
    if (state.email === null || state.email === "") nullFields.push('email');
    if (state.username === null || state.username === "") nullFields.push('username');
    if (state.degree === null || state.degree === "") nullFields.push('degree');
    if (state.nationality === null || state.nationality === "") nullFields.push('nationality');
    if (state.religious === null || state.religious === "") nullFields.push('religious');
    if (state.birthday === null || state.birthday === "") nullFields.push('birthday');
    if (state.major === null || state.major === "") nullFields.push('major');
    if (state.gender === null || state.gender === "") nullFields.push('gender');
    if (state.password === null || state.password === "") nullFields.push('password');
    if (state.confirm_password === null || state.confirm_password === "") nullFields.push('confirm_password');
    if (state.did_confirm_for_sharing_data === false) nullFields.push('did_confirm_for_sharing_data');
    // console.log(nullFields)
    return nullFields;
}

// Function To highlight unfilled inputs
function highlightAndFocusInputs(inputRefs) {
    console.log(inputRefs)
    inputRefs.forEach(ref => {
        try {
            if (ref && ref.current) {
                console.log("inside if", ref);
                ref.current.classList.add('input-error');
                ref.current.classList.add('input-no-focus');
                ref.current.focus();
            }
        } catch (error) {
            console.error("Error focusing on the input field:", error);
        }
    });
}
// Remove Highlight of the inputs
function removeError(inputRef) {
    if (inputRef && inputRef.current) {
        inputRef.current.classList.remove('input-error');
        inputRef.current.classList.remove('input-no-focus');
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function mapState(state) {
    return {
        user_profile: state.user_profile
    }
}

const updateProfile = (dispatch) => {
    return {
        user_profile: (item) => { dispatch({ type: 'UPDATE_PROFILE', item }) }
    }
}

export default connect(mapState, updateProfile)(withParams(withTranslation()(RegisterForm)));